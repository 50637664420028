import React from "react"
import Spinner from "./spinner"
import { FaPlay } from "react-icons/fa"
import "./media.scss"

const useHover = () => {
  const [hovered, set] = React.useState(false)
  return {
    hovered,
    bind: {
      onMouseEnter: () => set(true),
      onMouseLeave: () => set(false),
    },
  }
}

const ImageLoader: React.SFC<{ src: string }> = ({
  src,
  children,
  ...props
}) => {
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  React.useEffect(() => {
    // @ts-ignore
    const image = new Image()

    image.onload = () => {
      setLoading(false)
    }
    image.onerror = err => {
      setError(err)
    }

    image.src = src

    return () => {
      image.onload = null
      image.onerror = null
    }
  }, [])

  if (loading)
    return (
      <div className="image" {...props}>
        <Spinner />
      </div>
    )

  return (
    <div
      className="image"
      {...props}
      style={{
        backgroundImage: `url(${src})`,
      }}
    >
      {children}
    </div>
  )
}

const VideoPlayer: React.SFC<{ src: string }> = ({ src }) => {
  return (
    <div className="video">
      <video src={src} autoPlay muted loop />
    </div>
  )
}

export default ({ image_url, video_url }) => {
  const { hovered, bind } = useHover()

  return (
    <ImageLoader src={image_url} {...bind}>
      {hovered && video_url && <VideoPlayer src={video_url} />}
      {!hovered && video_url && <FaPlay color="white" fontSize="3em" />}
    </ImageLoader>
  )
}
