import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { useCookies } from "react-cookie"
import Layout from "../containers/layout"

const Private = ({ children }) => {
  const [{ token }, setCookie, removeCookie] = useCookies(["token"])
  if (!token) {
    // If we’re not logged in, redirect to the home page.
    // navigate(`/`)
    return (
      <Layout>
        <div className="jumbotron">
          <div className="container">
            <>
              <p className="lead">Please login to view this page.</p>
              <hr className="my-4" />
              <a
                className="btn btn-primary btn-lg"
                href={`/api/auth/login`}
              >
                Login
              </a>
            </>
          </div>
        </div>
      </Layout>
    )
  }

  return children
}

// Private.propTypes = {
//   component: PropTypes.any.isRequired,
// }

export default Private
