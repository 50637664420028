import React from "react"
import Header from "../components/header"
import "./layout.scss"
import ErrorBoundary from "react-error-boundary"

const ErrorDisplay = ({ componentStack, error }) => (
  <>
    <main className="contents">
      <div className="jumbotron">
        <div className="container">
          <h1 className="jumbotron-heading">Oops! An error occured.</h1>
          <p className="lead">Here’s what we know…</p>
          <p>
            <strong>Error:</strong> {error.toString()}
          </p>
        </div>
      </div>
    </main>
  </>
)

export default ({ children }) => (
  <>
    <Header />
    <ErrorBoundary FallbackComponent={ErrorDisplay}>
      <main className="contents">{children}</main>
    </ErrorBoundary>
  </>
)
