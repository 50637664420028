import React from "react"
import "./header.scss";
import { Link } from "gatsby"
import { useAppContext } from "../context";

import * as graphql from '../apollo/graphql'

const OnlineUserCount = () => {
  const onlineUserCount = graphql.useOnlineUsersCountQuery({ pollInterval: 10000 })

  return <span className="user-count">
    {onlineUserCount.data?.online_users[0]?.count} users active
  </span>
}

export default () => {
  const app = useAppContext();

  return (
    <header>
      <div className="navbar navbar-dark bg-dark box-shadow">
        <div className="container d-flex justify-content-between">
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <strong>#BuckUBack</strong>{app.user?.role === "editor" && <span className="ml-2">(<OnlineUserCount />)</span>}
          </Link>

          <ul className="nav nav-pills navbar-right">
            {app.user && (
              <>
                <li className="nav-item active">
                  <Link to="/poll" className="nav-link">
                    Poll
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/results" className="nav-link">
                    Results
                  </Link>
                </li>
              </>
            )}
            {app.user?.role === "editor" && (
              <li className="nav-item">
                <Link to="/submissions" className="nav-link">
                  Submissions
                </Link>
              </li>
            )}
            {!app.user?.role && (
              <a href="/api/auth/login" className="btn btn-primary">
                Login
              </a>
            )}
            {app.user?.role && (
              <>
                <a href="/api/auth/logout" className="btn btn-primary">
                  Logout{" "}
                  {app.user?.email}
                  {app.user?.role === "editor" && (
                    <span className="badge badge-warning ml-2">Editor</span>
                  )}
                </a>
              </>
            )}
          </ul>
        </div>
      </div>
    </header>
  );
};
